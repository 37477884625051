import React from 'react'
import { Nav } from '../components/baseComponents/nav/Nav'
import Footer from '../components/baseComponents/footer/Footer'

const DefaultLayout = (props) => {
    return (
        <>
            <div className='rootDiv'>
                <Nav />
                    {props.children}
                <Footer />
            </div>
        </>
    )
}

export default DefaultLayout