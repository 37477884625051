import './App.css';
import { Route, Routes } from 'react-router-dom';
import DefaultLayout from './layout/DefaultLayout';
import { Suspense, lazy } from 'react';

const Home = lazy(() => import('./pages/Home'))
const ModelDetails = lazy(() => import('./pages/ModelDetails'))
const ProjectList = lazy(() => import('./pages/ProjectList'))
const About = lazy(() => import('./pages/About'))

function App() {
  return (
    <DefaultLayout>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='ezay-design/' element={<Home />} />
          <Route path='/modelDetails/:name' element={<ModelDetails />} />
          <Route path='/projectsList/' element={<ProjectList />} />
          <Route path='/aboutUs/' element={<About />} />
          <Route path='/ezay-design/' element={<Home />} />
          <Route path='/ezay-design' element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Suspense>
    </DefaultLayout>
  );
}

export default App;
