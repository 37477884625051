import React from 'react'
import './Nav.css';
import Row from '../row/Row';
import { navItems } from '../../../data/staticDatas';
import logo from '../../../assets/images/logo.jpeg';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
export const Nav = () => {
    const navigaiton = useNavigate();
    const navClick = (path) => {
        if (path !== "/contact") {
            navigaiton(path)
        }
        else {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth',
            });
        }
    }
    return (
        <nav>
            <article className='navDummy'>

            </article>
            <Row noSplitters noPadding space>
                <article onClick={() => navigaiton('/')}>
                    <img src={logo} alt=''/>
                </article>
                <div className='navItems'>
                    {
                        navItems.map((item, i) => {
                            return (
                                <a onClick={() => navClick(item.path)} key={i}>
                                    {item.text}
                                </a>
                            )
                        })
                    }
                </div>
                <button className='menuIcon'>
                    <MenuIcon />
                </button>
                <div className='navMenu'>
                    {
                        navItems.map((item, i) => {
                            return (
                                <button className='menuButtons' onClick={() => navClick(item.path)} key={i}>
                                    {item.text}
                                </button>
                            )
                        })
                    }
                </div>
            </Row>
            <article className='navDummy'>

            </article>
        </nav>
    )
}
