import React from 'react'
import './Footer.css';
import Row from '../row/Row';
import dikLogo from '../../../assets/images/dikLogo.png'
import { useNavigate } from 'react-router-dom';
const Footer = () => {
    const navigate = useNavigate();
    return (
        <footer>
            <Row className="footerRow" space>
                <img src={dikLogo} className='footerLogo' alt='' />
                <div style={{ marginLeft: 100 }} className='footerParts part1'>
                    <span>
                        Şirket
                    </span>
                    <label onClick={() => navigate('/aboutUs')}>
                        Hakkımızda
                    </label>
                    <label onClick={() => navigate('/projectsList')}>
                        Projeler
                    </label>
                </div>
                <div className='footerParts part2'>
                    <span>
                        İletişim
                    </span>
                    <label>
                        designezay@gmail.com
                    </label>
                    <label>
                        +90542 226 69 08
                    </label>
                    <label>
                        +90538 886 35 45
                    </label>
                </div>
                <div className='footerTop'>
                    <div className='footerParts'>
                        <span>
                            Şirket
                        </span>
                        <label onClick={() => navigate('/aboutUs')}>
                            Hakkımızda
                        </label>
                        <label onClick={() => navigate('/projectsList')}>
                            Projeler
                        </label>
                    </div>
                    <div className='footerParts'>
                        <span>
                            İletişim
                        </span>
                        <label>
                            designezay@gmail.com
                        </label>
                        <label>
                            +90542 226 69 08
                        </label>
                        <label>
                            +90538 886 35 45
                        </label>
                    </div>
                </div>
                {/* <div className='footerParts part3'>
                    <span>
                        Adres
                    </span>
                    <label>
                        Hacettepe, Yönü Sk. No:2 06230 Altındağ,Ankara, Türkiye
                    </label>
                    <label>
                        Savaş mah. Şehit Pamir cd.
                        İskenderun, Hatay, Türkiye
                    </label>
                    <label>
                        Ahievran mah. 734.Sokak No:2
                        Merkez, Kırşehir, Türkiye
                    </label>
                </div> */}
            </Row>
        </footer>
    )
}

export default Footer