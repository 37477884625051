import info1 from '../assets/images/info1.jpg';
import info2 from '../assets/images/info2.jpg';
import info3 from '../assets/images/info3.jpg';
import info4 from '../assets/images/info4.jpg';
export const themeGrey = "#BEC3C7";
export const themeGreen = "#A2C11C";
export const themeDark = "#3C3B39";
export const whiteTransparent = "rgba(255,255,255,0.8)"
export const blackTransparent = "rgba(0,0,0,0.5)";
export const navItems = [
    {
        text: "Anasayfa",
        path: '/'
    },
    {
        text: "Hakkımızda",
        path: "/aboutUs",
    },
    {
        text: "Projelerimiz",
        path: "/projectsList",
    },
    {
        text: "İletişim",
        path: "/contact",
    }
]
export const title = "Modern Tasarımlarla Prefabrik Evlerde Konforlu ve Güvenli Yaşamın Anahtarını Sunuyoruz!!"
export const contact = "Size özel ev projelerimiz ve çeşitlendirilmiş prefabrik yapı seçeneklerimiz hakkında ayrıntılı bilgi almak ve fiyatlandırma seçeneklerimizi keşfetmek için bizimle iletişime geçin. Hayallerinizdeki evi tasarlama sürecine adım atmak için uzman ekibimiz size rehberlik edecek. Sizi memnuniyetle karşılamak ve ihtiyaçlarınıza uygun çözümler sunmak için buradayız. Bizimle iletişime geçmek için bugün bize ulaşın!"

export const models = [
    {
        id: 1,
        m: "115",
        name: "Model 1",
        rooms: `
            3 Oda<br/>
            1 Salon<br/>
            1 Banyo<br/>
            1 Teras<br/>
            1 Mutfak<br/>
            1 Veranda<br/>
       ` ,
        description: `
            -Galvenizli S235 kalite hafif çelik kurulumu<br/><br/>
            -İç ve dış duvar kaplama<br/><br/>
            -Çatı kaplama, oluk ve yağmur iniş<br/><br/>
            -Membran, taşyünü ve cam yünü döşeme<br/><br/>
            -Tüm evlerin çatısı beşik çatı olarak fiyatlandırılmıştır<br/><br/>
            -Elektrik Su Tesisatı<br/><br/>
            -Malzemelerinin Temini ve Uygulaması(Aydınlatma Armatürleri Hariç)<br/><br/>
            -Bina İç / Dış Cephe Boyası<br/><br/>
            -Giriş Çelik Kapı ve İç Amerikan Kapıların Teminini Montajı<br/><br/>
            -Pvc Çift Cam  Pencerelerin Temini ve Montajı<br/><br/>
            -Vitrifiye Ürünleri ve Tesisatlarının Çekilmesi(90x90 cm Duşakabin, Ayaklı Lavabo, Wc)<br/><br/>
        `,
        images: [
            "https://i.ibb.co/YDYL3F4/1-1.png",
            "https://i.ibb.co/F4kMSPn/1-2.png",
            "https://i.ibb.co/Fz6ZLHz/1-3.png",
        ],


    },
    {
        id: 2,
        m: "95",
        name: "Model 2",
        rooms: `
            3 Oda<br/>
            1 Salon<br/>
            3 Banyo<br/>
            1 Mutfak<br/>
            3 Veranda<br/>
        `,
        description: `
            -Galvenizli S235 kalite hafif çelik kurulumu<br/><br/>
            -İç ve dış duvar kaplama<br/><br/>
            -Çatı kaplama, oluk ve yağmur iniş<br/><br/>
            -Membran, taşyünü ve cam yünü döşeme<br/><br/>
            -Tüm evlerin çatısı beşik çatı olarak fiyatlandırılmıştır<br/><br/>
            -Elektrik Su Tesisatı<br/><br/>
            -Malzemelerinin Temini ve Uygulaması(Aydınlatma Armatürleri Hariç)<br/><br/>
            -Bina İç / Dış Cephe Boyası<br/><br/>
            -Giriş Çelik Kapı ve İç Amerikan Kapıların Teminini Montajı<br/><br/>
            -Pvc Çift Cam  Pencerelerin Temini ve Montajı<br/><br/>
            -Vitrifiye Ürünleri ve Tesisatlarının Çekilmesi(90x90 cm Duşakabin, Ayaklı Lavabo, Wc)<br/><br/>
        `,
        images: [
            "https://i.ibb.co/584YcPG/2-1.png",
            "https://i.ibb.co/rkQrm9h/2-2.png",
            "https://i.ibb.co/HPTXRg8/2-3.png",
            "https://i.ibb.co/MgPnzmD/2-4.png",
        ]
    },
    {
        id: 3,
        m: "75",
        name: "Model 3",
        rooms: `
            2 Oda<br/>
            1 Salon<br/>
            1 Banyo<br/>
            1 Mutfak<br/>
            1 Veranda<br/>
        `,
        description: `
            -Galvenizli S235 kalite hafif çelik kurulumu<br/><br/>
            -İç ve dış duvar kaplama<br/><br/>
            -Çatı kaplama, oluk ve yağmur iniş<br/><br/>
            -Membran, taşyünü ve cam yünü döşeme<br/><br/>
            -Tüm evlerin çatısı beşik çatı olarak fiyatlandırılmıştır<br/><br/>
            -Elektrik Su Tesisatı<br/><br/>
            -Malzemelerinin Temini ve Uygulaması(Aydınlatma Armatürleri Hariç)<br/><br/>
            -Bina İç / Dış Cephe Boyası<br/><br/>
            -Giriş Çelik Kapı ve İç Amerikan Kapıların Teminini Montajı<br/><br/>
            -Pvc Çift Cam  Pencerelerin Temini ve Montajı<br/><br/>
            -Vitrifiye Ürünleri ve Tesisatlarının Çekilmesi(90x90 cm Duşakabin, Ayaklı Lavabo, Wc)<br/><br/>
        `,
        images: [
            "https://i.ibb.co/84cFJ4c/3-1.png",
            "https://i.ibb.co/j6Ny8bc/3-2.png",
            "https://i.ibb.co/SKrzBm3/3-3.png",
        ]
    },
    {
        id: 4,
        m: "130",
        name: "Model 4",
        rooms: `
            3 Oda<br/>
            1 Salon<br/>
            2 Banyo<br/>
            1 Mutfak<br/>
            1 Veranda<br/>
            `,
        description: `
            -Galvenizli S235 kalite hafif çelik kurulumu<br/><br/>
            -İç ve dış duvar kaplama<br/><br/>
            -Çatı kaplama, oluk ve yağmur iniş<br/><br/>
            -Membran, taşyünü ve cam yünü döşeme<br/><br/>
            -Tüm evlerin çatısı beşik çatı olarak fiyatlandırılmıştır<br/><br/>
            -Elektrik Su Tesisatı<br/><br/>
            -Malzemelerinin Temini ve Uygulaması(Aydınlatma Armatürleri Hariç)<br/><br/>
            -Bina İç / Dış Cephe Boyası<br/><br/>
            -Giriş Çelik Kapı ve İç Amerikan Kapıların Teminini Montajı<br/><br/>
            -Pvc Çift Cam  Pencerelerin Temini ve Montajı<br/><br/>
            -Vitrifiye Ürünleri ve Tesisatlarının Çekilmesi(90x90 cm Duşakabin, Ayaklı Lavabo, Wc)<br/><br/>
        `,
        images: [
            "https://i.ibb.co/F4JbDbd/4-1.png",
        ]
    },
    {
        id: 5,
        m: "180",
        name: "Model 5",
        rooms: `
            4 Oda<br/>
            1 Yaşam Alanı<br/>
            2 Balkon<br/>
            3 Banyo<br/>
            1 Mutfak<br/>
            2 Veranda<br/>
            3 Giyinme Odası<br/>
            `,
        description: `
            -Galvenizli S235 kalite hafif çelik kurulumu<br/><br/>
            -İç ve dış duvar kaplama<br/><br/>
            -Çatı kaplama, oluk ve yağmur iniş<br/><br/>
            -Membran, taşyünü ve cam yünü döşeme<br/><br/>
            -Tüm evlerin çatısı beşik çatı olarak fiyatlandırılmıştır<br/><br/>
            -Elektrik Su Tesisatı<br/><br/>
            -Malzemelerinin Temini ve Uygulaması(Aydınlatma Armatürleri Hariç)<br/><br/>
            -Bina İç / Dış Cephe Boyası<br/><br/>
            -Giriş Çelik Kapı ve İç Amerikan Kapıların Teminini Montajı<br/><br/>
            -Pvc Çift Cam  Pencerelerin Temini ve Montajı<br/><br/>
            -Vitrifiye Ürünleri ve Tesisatlarının Çekilmesi(90x90 cm Duşakabin, Ayaklı Lavabo, Wc)<br/><br/>
        `,
        images: [
            "https://i.ibb.co/6m1SDLd/5-1.png",
            "https://i.ibb.co/TKPfw1R/5-2.png",
            "https://i.ibb.co/MGwcrs9/5-3.png",
        ]
    },
    {
        id: 6,
        m: "75",
        name: "Model 6",
        rooms: `
            2 Oda<br/>
            1 Salon<br/>
            1 Banyo<br/>
            1 Mutfak<br/>
            1 Veranda<br/>
            `,
        description: `
            -Galvenizli S235 kalite hafif çelik kurulumu<br/><br/>
            -İç ve dış duvar kaplama<br/><br/>
            -Çatı kaplama, oluk ve yağmur iniş<br/><br/>
            -Membran, taşyünü ve cam yünü döşeme<br/><br/>
            -Tüm evlerin çatısı beşik çatı olarak fiyatlandırılmıştır<br/><br/>
            -Elektrik Su Tesisatı<br/><br/>
            -Malzemelerinin Temini ve Uygulaması(Aydınlatma Armatürleri Hariç)<br/><br/>
            -Bina İç / Dış Cephe Boyası<br/><br/>
            -Giriş Çelik Kapı ve İç Amerikan Kapıların Teminini Montajı<br/><br/>
            -Pvc Çift Cam  Pencerelerin Temini ve Montajı<br/><br/>
            -Vitrifiye Ürünleri ve Tesisatlarının Çekilmesi(90x90 cm Duşakabin, Ayaklı Lavabo, Wc)<br/><br/>
        `,
        images: [
            "https://i.ibb.co/LrRwzK7/6-1.png",
            "https://i.ibb.co/9ss7ZtX/6-2.png",
            "https://i.ibb.co/HnV45YX/6-3.png",
            "https://i.ibb.co/h9JRVGq/6-4.png",
            "https://i.ibb.co/TkHXxcn/6-5.png"
        ]
    },

    {
        id: 8,
        m: "250",
        name: "Model 8",
        rooms: `
            5 Oda<br/>
            
            1 Salon<br/>
            4 Banyo<br/>
            1 Mutfak<br/>
            1 Veranda<br/>
            `,
        description: `
            -Galvenizli S235 kalite hafif çelik kurulumu<br/><br/>
            -İç ve dış duvar kaplama<br/><br/>
            -Çatı kaplama, oluk ve yağmur iniş<br/><br/>
            -Membran, taşyünü ve cam yünü döşeme<br/><br/>
            -Tüm evlerin çatısı beşik çatı olarak fiyatlandırılmıştır<br/><br/>
            -Elektrik Su Tesisatı<br/><br/>
            -Malzemelerinin Temini ve Uygulaması(Aydınlatma Armatürleri Hariç)<br/><br/>
            -Bina İç / Dış Cephe Boyası<br/><br/>
            -Giriş Çelik Kapı ve İç Amerikan Kapıların Teminini Montajı<br/><br/>
            -Pvc Çift Cam  Pencerelerin Temini ve Montajı<br/><br/>
            -Vitrifiye Ürünleri ve Tesisatlarının Çekilmesi(90x90 cm Duşakabin, Ayaklı Lavabo, Wc)<br/><br/>
        `,
        images: [
            "https://i.ibb.co/Vt3H2DH/8-1.png",
            "https://i.ibb.co/pwM6d2V/8-2.png",
        ]
    },
    {
        id: 7,
        m: "250",
        name: "Model 7",
        rooms: `
            5 Oda<br/>
            
            1 Salon<br/>
            4 Banyo<br/>
            1 Mutfak<br/>
            1 Veranda<br/>
            `,
        description: `
            -Galvenizli S235 kalite hafif çelik kurulumu<br/><br/>
            -İç ve dış duvar kaplama<br/><br/>
            -Çatı kaplama, oluk ve yağmur iniş<br/><br/>
            -Membran, taşyünü ve cam yünü döşeme<br/><br/>
            -Tüm evlerin çatısı beşik çatı olarak fiyatlandırılmıştır<br/><br/>
            -Elektrik Su Tesisatı<br/><br/>
            -Malzemelerinin Temini ve Uygulaması(Aydınlatma Armatürleri Hariç)<br/><br/>
            -Bina İç / Dış Cephe Boyası<br/><br/>
            -Giriş Çelik Kapı ve İç Amerikan Kapıların Teminini Montajı<br/><br/>
            -Pvc Çift Cam  Pencerelerin Temini ve Montajı<br/><br/>
            -Vitrifiye Ürünleri ve Tesisatlarının Çekilmesi(90x90 cm Duşakabin, Ayaklı Lavabo, Wc)<br/><br/>
        `,
        images: [
            "https://i.ibb.co/TkmYG5c/7-6.png",
            "https://i.ibb.co/TLh1KXw/7-5.png",
            "https://i.ibb.co/M5VK8T1/7-4.png",
            "https://i.ibb.co/qp7KgvN/7-3.png",
            "https://i.ibb.co/dQ1vW5B/7-1.png",
            "https://i.ibb.co/kDMVLVM/7-2.png",
        ]
    },
]

export const informations = [
    {
        title: "Biz Kimiz",
        text: "Ezay Design olarak Tüm Türkiye’ye müstakil ev imalatı yapıyoruz. İsteklerinize uygun kaliteli yaşam alanları inşaa ederek müşteri memnuniyetini ön planda tutmak tek hedefimiz ve çabamızdır.",
        image: info1
    },
    {
        title: "Müşteri Memnuniyeti",
        text: "Aklınıza takılan bütün soruları sorabilir, siz de hayallerinizdeki ev modelini inşaa ettirmek için bizimle iletişime geçebilirsiniz. Yaptığımız tüm evlerde memnuniyet garantisi bizim için en ön planda olup, bunun garantisini size veriyoruz. Fiyat alırken tasarımınızda tercih edeceğiniz  malzeme detaylarını sormayı unutmayınız.",
        image: info2
    },
    {
        title: "Misyonumuz",
        text: "Müşteri ihtiyaçlarını doğru analiz ederek yenilikçi ve yaratıcı yaklaşımımız, hayal gücümüz ve cesur girişimlerimizle müşteri memnuniyetini sağlamaktır.",
        image: info3
    },
    {
        title: "Vizyonumuz",
        text: "Müşterilerimizin güvenini her zaman haklı çıkarmak için giderek artan kalite bilincine, teknolojik gelişmeleri de takip ederek düşük maliyetlerde, istenen zaman ve yerde en iyi şekilde hizmet veren firma olmaktır.",
        image: info4
    }
]

export const beltData = [
    {
        title: "Yenilikçi Mimarlar",
        text: "Alanında uzman ve yeniliklere açık mimarlarımız sizin için çalışıyor.",
    },
    {
        title: "Profesyonel Ekip",
        text: "Çelik yapılarımızın teslim sürecine kadar profesyonel ekibimiz sizinle.",
    },
    {
        title: "Zamanında Teslimat",
        text: "Zamanında ve şartnamelere uygun teslimat sağlamaktayız.",
    },
]
