import React from 'react'
import './Row.css';
const Row = (props) => {
  return (
    <div
      className={'row ' + props.className}
      style={
        {
          ...props.style,
          justifyContent: props.space ? 'space-between' : 'flex-start',
          paddingTop: !props.noPadding ? 16 : 0,
          paddingBottom: !props.noPadding ? 16 : 0,
        }
      }>
      {
        !props.noSplitters ?
          <article className='dummyRowPlaces'>

          </article> : null
      }

      {props.children}
      {
        !props.noSplitters ?
          <article className='dummyRowPlaces'>

          </article> : null
      }
    </div>
  )
}

export default Row